.paperStyle {
  width: 300px;
  padding: 30px 20px;
  margin: 20px auto;
}

.headerStyle {
  margin: 0;
}

.avatarStyle {
  background-color: #1bbd7e;
}

.formStyle {
  text-align: center;
}
